import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
import queryString from 'query-string';
export const getAllTypes = createAsyncThunk('type/getAllTypes', async ({ search, limit, page, archived, fromSeed, options }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, archived, fromSeed, options }
            : { search, limit, page, archived, fromSeed, options });
        const response = await apiKey.get(`/types?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createType = createAsyncThunk('types/createType', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/types`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateType = createAsyncThunk('types/updateType', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/types/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getTypeById = createAsyncThunk('types/getTypeById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/types/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteType = createAsyncThunk('types/deleteType', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/types/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
